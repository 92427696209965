const styles = {
  mainContainer: {
    flex: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F8E68D',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    padding: '2rem 0',
    maxWidth: 600,
  },
  title: {
    fontSize: '2rem',
    paddingLeft: '15px',
  },
  projectsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    maxWidth: 600,
  },
  sectionTitle: {
    alignSelf: 'flex-start',
    fontSize: '1.7rem',
    margin: '10px 0',
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '20px 0 100px 0',
  },
  social: {
    padding: '20px',
    borderRadius: '100%',
    width: 50,
    height: 50,
    backgroundColor: '#F8E68D',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 20px',

    boxShadow: `0 4.6px 7.7px rgba(0, 0, 0, 0.007),
    0 9.2px 12.8px rgba(0, 0, 0, 0.012),
    0 13.9px 16.7px rgba(0, 0, 0, 0.015),
    0 19.3px 20.2px rgba(0, 0, 0, 0.017),
    0 25.8px 23.7px rgba(0, 0, 0, 0.018),
    0 34.5px 27.7px rgba(0, 0, 0, 0.018),
    0 47.4px 32.5px rgba(0, 0, 0, 0.018),
    0 68.4px 39.2px rgba(0, 0, 0, 0.019),
    0 108px 49.9px rgba(0, 0, 0, 0.022),
    0 218px 80px rgba(0, 0, 0, 0.04)`,
  },
};

export default styles;
