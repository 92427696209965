import React from 'react';
import injectSheet from 'react-jss';
import styles from './Project.styles';

const Project = ({ project, index, classes }) => {
  return (
    <a href={project.url} key={index} className={classes.project}>
      <div className={classes.projectHeader}>
        <img src={project.icon} width={40} alt={`${project.name} icon`} />
        <span className={classes.projectName}>{project.name}</span>
      </div>
      <span className={classes.projectDescription}>{project.description}</span>
    </a>
  );
};

export default injectSheet(styles)(Project);
