import belablok from './assets/images/belablok.png';
import belaonline from './assets/images/belaonline.png';

const projects = [
  {
    name: 'Bela Online',
    description:
      'The Croatian version of the game Belote (Bela). Multiplayer, free, available on web, iOS and Android.',
    icon: belaonline,
    url: 'https://app.bela.fun/download',
    instagram: 'https://app.belablok.com/instagram',
    category: ['game', 'cards', 'casual'],
    platforms: ['iOS', 'Android', 'web'],
  },
  {
    name: 'Bela Blok',
    description:
      'The application to track score in a game of Belote. Supports games with 4 players up to 501, 701, or 1001 points.',
    icon: belablok,
    url: 'https://app.belablok.com/download',
    instagram: 'https://app.belablok.com/instagram',
    category: ['utility'],
    platforms: ['iOS', 'Android', 'web'],
  },
];

export default projects;
