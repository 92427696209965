const styles = {
  project: {
    flex: 1,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '1.5em',
    backgroundColor: '#FFF2B0',
    borderRadius: 10,
    margin: '1rem 0',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: `0 4.6px 7.7px rgba(0, 0, 0, 0.007),
    0 9.2px 12.8px rgba(0, 0, 0, 0.012),
    0 13.9px 16.7px rgba(0, 0, 0, 0.015),
    0 19.3px 20.2px rgba(0, 0, 0, 0.017),
    0 25.8px 23.7px rgba(0, 0, 0, 0.018),
    0 34.5px 27.7px rgba(0, 0, 0, 0.018),
    0 47.4px 32.5px rgba(0, 0, 0, 0.018),
    0 68.4px 39.2px rgba(0, 0, 0, 0.019),
    0 108px 49.9px rgba(0, 0, 0, 0.022),
    0 218px 80px rgba(0, 0, 0, 0.04)`,
  },
  projectHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '0 0 10px 0',
  },
  projectName: {
    margin: '0px 10px',
    fontSize: '1.4em',
  },
  projectDescription: {
    padding: '.5rem 0',
    color: `rgba(0, 0, 0, 0.6)`,
    textAlign: 'justify',
  },
};

export default styles;
