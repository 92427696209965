import React from "react";
import { Instagram, Mail, Coffee } from "react-feather";
import injectSheet from "react-jss";
import logo from "../assets/images/sombrerito.png";
import projects from "../projects";
import styles from "./HomeScreen.styles";
import Project from "./Project";

const HomeScreen = (props) => {
  const { classes } = props;
  return (
    <div className={classes.mainContainer}>
      <div className={classes.projectsContainer}>
        <a href="/" className={classes.headerContainer}>
          <img src={logo} width="50" className="App-logo" alt="logo" />
          <span className={classes.title}>Sombrerito</span>
        </a>
        <span className={classes.sectionTitle}>Our Work</span>
        {projects.map((project, index) => {
          return <Project project={project} index={index} />;
        })}
        <span className={classes.sectionTitle}>Connect With Us</span>
        <div className={classes.socialContainer}>
          <a
            href="https://buymeacoffee.com/sombrerito"
            className={classes.social}
          >
            <Coffee />
          </a>
          <a
            href="https://instagram.com/falicetvrti?igshid=zvbgvh8z6hdh"
            className={classes.social}
          >
            <Instagram />
          </a>
          <a href="mailto:hi@sombrerito.co" className={classes.social}>
            <Mail />
          </a>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(styles)(HomeScreen);
